import React from 'react';

import WeducWebAttribution from '@accrosoft-ltd/accropress-websites-components/dist/esm/WeducWebAttribution';

const gatsbyConfig = require('@accrosoft-ltd/accropress-theme-alfred/theme-config.js');

const gatsbyConfigSiteMetaData = gatsbyConfig.siteMetadata;
const gatsbyConfigPlugins = gatsbyConfig.plugins;
const gatsbyConfigPluginsAP = gatsbyConfigPlugins.find(
  (o) => o.resolve === 'gatsby-plugin-accropress',
);

const gatsbyConfigPluginsAPOptions = gatsbyConfigPluginsAP.options;

const Footer = (props) => {
  let footerWidgetData = [];

  if (gatsbyConfigPluginsAPOptions) {
    footerWidgetData =
      gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload &&
        gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload.length > 0
        ? gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload.find(
          (o) => o.widgetID === 'footer',
        )
        : gatsbyConfigPluginsAPOptions.siteSettings.theme &&
        gatsbyConfigPluginsAPOptions.siteSettings.theme.widgetsDefinition &&
        gatsbyConfigPluginsAPOptions.siteSettings.theme.widgetsDefinition.find(
          (o) => o.widgetID === 'footer',
        );
  }

  return (
    <footer>
      <div className="container">
        <div className="row">
          {footerWidgetData &&
            footerWidgetData.children.map((widget, i) => (
              <>
                {widget.id === 'footer-contact-details' ? (
                  <div className="col-12 col-3-m">
                    <div className="header">{widget.name}</div>
                    <div className="address">
                      <span className="addressName">
                        {widget.children && widget.children[0]
                          ? widget.children[0][0].value ||
                          widget.children[0][0].default
                          : widget.columns[0].value ||
                          widget.columns[0].default}
                      </span>
                      <p>
                        {widget.children && widget.children[0]
                          ? widget.children[0][1].value ||
                          widget.children[0][1].default
                          : widget.columns[0].value ||
                          widget.columns[1].default}
                      </p>
                      <p>
                        {widget.children && widget.children[0]
                          ? widget.children[0][2].value ||
                          widget.children[0][2].default
                          : widget.columns[0].value ||
                          widget.columns[2].default}
                      </p>
                    </div>
                    <div className="contact">
                      <a
                        href={`tel:${widget.children && widget.children[0]
                          ? widget.children[0][3].value ||
                          widget.children[0][3].default
                          : widget.columns[0].value ||
                          widget.columns[3].default
                          }`}
                      >
                        <span className="fas fa-mobile-alt"></span>
                        Tel:{' '}
                        <a
                          href={`tel:${widget.children && widget.children[0]
                            ? widget.children[0][3].value ||
                            widget.children[0][3].default
                            : widget.columns[0].value ||
                            widget.columns[3].default
                            }`}
                        >
                          {widget.children && widget.children[0]
                            ? widget.children[0][3].value ||
                            widget.children[0][3].default
                            : widget.columns[0].value ||
                            widget.columns[3].default}
                        </a>
                      </a>
                      <a
                        href={`mailto:${widget.children && widget.children[0]
                          ? widget.children[0][4].value ||
                          widget.children[0][4].default
                          : widget.columns[0].value ||
                          widget.columns[4].default
                          }`}
                      >
                        <span className="far fa-envelope"></span>{' '}
                        {widget.children && widget.children[0]
                          ? widget.children[0][4].value ||
                          widget.children[0][4].default
                          : widget.columns[0].value ||
                          widget.columns[4].default}
                      </a>
                    </div>
                  </div>
                ) : widget.id === 'footer-useful-links' ||
                  widget.id === 'footer-useful-resources' ? (
                  <div className="col-12 col-3-m">
                    <div className="header">{widget.name}</div>
                    {widget.children && widget.children[0] ? (
                      <ul>
                        {widget.children.map((widgetChild) => (
                          <>
                            {widgetChild.map((link, i) => (
                              <li>
                                <span className="fas fa-angle-right"></span>
                                <a href={link.value}>{link.text}</a>
                              </li>
                            ))}
                          </>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        {widget.columns[0] &&
                          widget.columns[0].map((link, i) => (
                            <li>
                              <span className="fas fa-angle-right"></span>
                              <a href={link.value}>{link.text}</a>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : widget.id === 'footer-information' ? (
                  <div className="col-12 col-3-m">
                    <div className="header">{widget.name}</div>
                    {widget.children && widget.children[0] ? (
                      <ul>
                        {widget.children[0].map((link, i) => (
                          <li>
                            <span className="fas fa-angle-right"></span>
                            <a href={link.value}>{link.text}</a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        {widget.columns[0] &&
                          widget.columns[0].map((link, i) => (
                            <li>
                              <span className="fas fa-angle-right"></span>
                              <a href={link.value}>{link.text}</a>
                            </li>
                          ))}
                      </ul>
                    )}
                    <div className="copyright">
                      &copy; {gatsbyConfigSiteMetaData.title}
                    </div>
                    <div className="footer-icons">
                      <div className="footer-icons-icon">
                        <img src="https://cdn1.accropress.com/EcoLogoFIN.png" alt="Eco" />
                      </div>
                    </div>
                    <div className="websites-by-weduc">
                      <WeducWebAttribution
                        campaignName={gatsbyConfigSiteMetaData.title}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-12 col-3-m"></div>
                )}
              </>
            ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
